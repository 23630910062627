/**
 * 区域管理
 */
import { axios } from '@/utils/request'

//区域列表
export function areaPage(parameter) {
  return axios({
    url: '/area/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//添加区域
export function areaAdd(data) {
  return axios({
    url: '/area/add',
    method: 'post',
    data,
  })
}

//编辑区域
export function areaEdit(data) {
  return axios({
    url: '/area/edit',
    method: 'post',
    data,
  })
}

//复制整个区域的商品,推荐位,轮播图到另一个区域
export function areaCopyGoods(data) {
  return axios({
    url: '/area/copyGoods',
    method: 'post',
    data,
  })
}

//获取所有非业务员用户
export function listStore(data) {
  return axios({
    url: '/customer/listStore',
    method: 'post',
    params: data,
  })
}
//绑定区域商家
export function bindStore(data) {
  return axios({
    url: '/area/bindStore',
    method: 'post',
    data,
  })
}

//复制商家商品
export function copyGoods(data) {
  return axios({
    url: '/goods/copyGoods',
    method: 'post',
    data: data,
  })
}
